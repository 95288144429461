<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :lg="12" :xs="21">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>

        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            :style="{ height: '63px', paddingLeft: 0 }"
            :title="formInfo.name"
            @back="backToFormsPage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Body -->
  <a-layout class="app-forms-viewer-page app-layout-nested">
    <template v-if="userFormReviewData.length">
      <!-- Sider -->
      <a-layout-sider
        class="layout-nested-sider"
        v-if="!device.mobile"
        :style="{ overflow: 'auto' }"
      >
        <div class="user-list">
          <a-row
            @click="selectForm(item)"
            v-for="item in activedFormsData"
            class="user-list-item"
            :class="selectedUserId===item.user_id ? 'item--selected' : ''"
          >
            <a-col flex="60px" align="center">
              <div class="item-avatar">
                <a-avatar
                  :src="item.user.avatar!=null ? item.user.avatar.uri : require('@/assets/icons/avatar.png')"
                />
              </div>
            </a-col>

            <a-col flex="1">
              <div class="item-title">{{ item.user.name }}</div>
              <div class="item-text">{{ item.user.member_id }}</div>
            </a-col>
          </a-row>
        </div>
      </a-layout-sider>
      <!-- Sider -->

      <!-- Content -->
      <a-layout-content
        class="layout-nested-content"
        :style="{ padding: '24px', overflow: 'auto' }"
      >
        <a-card class="form-card">
          <div class="letwecare-form">
            <div class="form__header">
              <div class="header-title">{{ formInfo.name }}</div>
            </div>
            <div class="form__body">
              <a-form>
                <div
                  class="form-section"
                  v-for="(form, formIdx) in userFormReviewData"
                >
                  <div class="section-title">{{ form.section_title }}</div>

                  <div
                    class="section-fields"
                    v-for="(field, fieldIdx) in form.fields"
                  >
                    <a-row>
                      <a-col :span="24">
                        <a-form-item
                          :label="field.name"
                          :required="field.required"
                        >
                          <template v-if="field.type == 'number'">
                            <a-input-number
                              v-model:value="field.value"
                              :disabled="submitting"
                              readonly
                            />
                          </template>

                          <template v-if="field.type == 'text-field'">
                            <a-input
                              v-model:value="field.value"
                              :disabled="submitting"
                              readonly
                            />
                          </template>

                          <template v-if="field.type == 'textarea'">
                            <a-textarea
                              v-model:value="field.value"
                              :auto-size="{ minRows: 4 }"
                              :disabled="submitting"
                              readonly
                            />
                          </template>

                          <template v-if="field.type == 'radio'">
                            <a-radio-group
                              v-model:value="field.value"
                              disabled
                            >
                              <a-radio  
                                v-for="opt in field.selection_items"
                                :value="opt.label"
                              >{{ opt.label }}</a-radio>
                            </a-radio-group>
                          </template>

                          <template v-if="field.type == 'checkbox'">
                            <a-checkbox-group
                              v-model:value="field.value"
                              disabled
                            >
                              <a-checkbox
                                v-for="opt in field.selection_items"
                                :value="opt.label"
                              >{{ opt.label }}</a-checkbox>
                            </a-checkbox-group>
                          </template>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-form>
            </div>
          </div>
        </a-card>
      </a-layout-content>
      <!-- Content -->
    </template>
    
    <template v-else>
      <div algin="center" :style="{ margin: '120px 0' }">
        <a-empty :image="simpleImage" description="尚未有使用者填寫問卷" />
      </div>
    </template>
  </a-layout>
  <!-- Layout Body -->
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { Empty } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Data */
    const formInfo = ref({});
    const activedFormsData = ref([]);
    const selectedUserId = ref('');
    const userFormReviewData = ref({});

    /* Api */
    const getFormInfo = (async () => {
      const response = await api.v1.form.findOne(route.params.form_id);
      formInfo.value = response.data.result;
      console.log(formInfo.value);
    });

    const getActivedFormsData = (async () => {
      const response = await api.v1.form.activedList({
        form_id: route.params.form_id
      });
      activedFormsData.value = response.data.result;
      console.log(activedFormsData.value)
    });

    /* Function */
    const selectForm = (data => {
      selectedUserId.value = data.user_id;
      userFormReviewData.value = data.form_list;
      console.log(userFormReviewData.value);
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/forms',
        breadcrumbName: '表單管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      await getFormInfo();
      await getActivedFormsData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/forms/view/${ route.params.form_id }`,
        breadcrumbName: formInfo.value.name
      });
      
      if (activedFormsData.value.length > 0) {
        selectForm(activedFormsData.value[0]);
      }
    });

    return {
      device,

      /* Store */
      authStore,
      appStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Data */
      formInfo,
      activedFormsData,
      selectedUserId,
      userFormReviewData,

      /* Function */
      selectForm,
    }
  },
  methods: {
    /* router */
    backToFormsPage() {
      this.$router.push({ name: 'AppForms' });
    },
  }
})
</script>

<style lang="scss">
.app-forms-viewer-page {
  .user-list {
    .user-list-item {
      padding: 6px;
      border-left: 3px solid white;
      cursor: pointer;

      &.item--selected {
        border-left: 3px solid orange;
      }

      .item-avatar {
        display: flex;
        justify-content: center;
        align-self: center;
      }

      .item-title {
        font-size: 1rem;
      }

      .item-text {
        color: #757575;
        font-size: .6rem;
      }
    }
  }

  .form-card {
    max-width: 750px;
    padding: 16px;
    margin: 0 auto;
  }
}
</style>